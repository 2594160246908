/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #303030;
}

.link {
  color: #408bf9;
  cursor: pointer;
  text-decoration: underline;
}

table {
  border-collapse: separate;
}
